import * as Sentry from '@sentry/node'
import {useGetAuthUserQuery} from 'generated/graphql'
import {FC, useEffect} from 'react'

export const SetSentryUser: FC = () => {
    const {data} = useGetAuthUserQuery()
    useEffect(() => {
        if (data?.authUser) {
            Sentry.setUser({
                id: data.authUser.id,
                email: data.authUser.email,
            })
        } else {
            Sentry.setUser(null)
        }
    }, [data])
    return null
}
