import {t} from '@lingui/macro'
import {Divider, List, ListItem, useMediaQuery} from '@material-ui/core'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {Add as AddIcon, Home as HomeIcon} from '@material-ui/icons'
import {AppLogo} from 'components/app-logo'
import {NotificationBell} from 'components/notifications/notification-bell'
import {useUser} from 'contexts/user'
import {
    AccountIcon,
    ActivityIcon,
    AdminIcon,
    AnalyticsIcon,
    ContactIcon,
    HelpIcon,
    LogoutIcon,
    QuoteIcon,
    SaleIcon,
    UserIcon,
} from 'icons'
import React, {FC} from 'react'
import * as settings from 'settings'

import {AppMenuLink} from './app-menu-link'

interface AppMenuProps {
    onClose?(): void
    drawerExpanded?: boolean
}

export const AppMenu: FC<AppMenuProps> = ({onClose, drawerExpanded}) => {
    const {user} = useUser()
    const classes = useStyles()
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

    return (
        <>
            <List component='nav' onClick={onClose} className={classes.mainNav}>
                <div className={classes.logo}><AppLogo /></div>
                <Divider className={classes.divider} />
                {mdUp && <NotificationBell asListItem={true} drawerExpanded={drawerExpanded} />}
                <AppMenuLink href='/quotes/new' label={t`New quote`} icon={<AddIcon />} />
                {mdUp && <AppMenuLink href='/home' label={t`Home`} icon={<HomeIcon />} />}
                <AppMenuLink href='/dashboard' label={t`Analytics`} icon={<AnalyticsIcon />} />
                <AppMenuLink href='/quotes' label={t`Quotes`} icon={<QuoteIcon />} />
                <AppMenuLink href='/sales' label={t`Sales`} icon={<SaleIcon />} />
                <AppMenuLink href='/contacts' label={t`Contacts`} icon={<ContactIcon />} />
                <AppMenuLink href='/accounts' label={t`Accounts`} icon={<AccountIcon />} />
                <AppMenuLink href='/activities' label={t`Activities`} icon={<ActivityIcon />} />
            </List>
            <List component='nav' style={{flexDirection: 'column-reverse'}} onClick={onClose}>
                {user?.isTenantStaff && <AppMenuLink href='/ui-admin' label={t`Administration`} icon={<AdminIcon />} />}
                {mdUp && <AppMenuLink href='/users/profile' label={t`Profile`} icon={<UserIcon />} />}
                <AppMenuLink href='mailto:info@sappio.app' label={t`Help`} icon={<HelpIcon />} />
                <AppMenuLink href={settings.LOGOUT_URL} label={t`Logout`} icon={<LogoutIcon />} />
                <Divider className={classes.divider} />
                <ListItem>
                    <AppLogo sappio={true} variant='light' />
                </ListItem>
            </List>
        </>
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    mainNav: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    logo: {
        margin: theme.spacing(2),
        textAlign: 'center',
    },
    logoSappio: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(15),
        marginLeft: theme.spacing(2),
        textAlign: 'center',
    },
    divider: {
        backgroundColor: 'white !important',
    },
}))
