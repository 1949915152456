import {ApolloProvider} from '@apollo/client'
import DayjsUtils from '@date-io/dayjs'
import CssBaseline from '@material-ui/core/CssBaseline'
import {ThemeProvider} from '@material-ui/core/styles'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import * as Sentry from '@sentry/node'
import acceptLanguageParser from 'accept-language-parser'
import {apolloClient} from 'api'
import {Layout} from 'components/layout'
import {PageViewTracker} from 'components/page-view-tracker'
import {SetSentryUser} from 'components/set-sentry-user'
import {NotificationProvider} from 'contexts/notification'
import {TenantProvider} from 'contexts/tenant'
import {UserProvider} from 'contexts/user'
import {I18nProvider} from 'i18n'
import App, {AppContext, AppProps} from 'next/app'
import getConfig from 'next/config'
import Head from 'next/head'
import {SnackbarProvider} from 'notistack'
import {useEffect, useState} from 'react'
import * as settings from 'settings'
import {theme} from 'theme'

const {publicRuntimeConfig} = getConfig()

if (publicRuntimeConfig.sentryPublicDSN) {
    Sentry.init({
        dsn: publicRuntimeConfig.sentryPublicDSN,
        release: publicRuntimeConfig.version,
        environment: publicRuntimeConfig.environment,
    })
}

type CustomAppProps = AppProps & {
    browserLanguage: string
    err?: Error // Workaround for https://github.com/vercel/next.js/issues/8592
}

const CustomApp = ({Component, pageProps, browserLanguage, err}: CustomAppProps) => {
    const [language, setLanguage] = useState<string>(browserLanguage)

    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side')
        if (jssStyles && jssStyles.parentNode) jssStyles.parentNode.removeChild(jssStyles)
    })

    useEffect(() => {
        const storedLanguage = window?.localStorage.getItem('lang')
        if (storedLanguage) setLanguage(storedLanguage)
    })

    return (
        <>
            <Head>
                <title>Sappio</title>
                <meta name='viewport' content='width=device-width, initial-scale=1, user-scalable=no' />
            </Head>
            <ApolloProvider client={apolloClient}>
                <I18nProvider initialLanguage={language}>
                    <ThemeProvider theme={theme}>
                        <SnackbarProvider anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
                            <MuiPickersUtilsProvider utils={DayjsUtils}>
                                <UserProvider>
                                    <TenantProvider>
                                        <CssBaseline />
                                        <NotificationProvider>
                                            <Layout>
                                                <Component {...pageProps} err={err} />
                                            </Layout>
                                        </NotificationProvider>
                                        <PageViewTracker />
                                        <SetSentryUser />
                                    </TenantProvider>
                                </UserProvider>
                            </MuiPickersUtilsProvider>
                        </SnackbarProvider>
                    </ThemeProvider>
                </I18nProvider>
            </ApolloProvider>
        </>
    )
}

CustomApp.getInitialProps = async (appContext: AppContext) => {
    const browserLanguage = acceptLanguageParser.pick(
        settings.LANGUAGES.map(({code}) => code),
        appContext.ctx.req?.headers['accept-language'] || '',
    ) || settings.LANGUAGE_CODE
    const appProps = await App.getInitialProps(appContext)
    return {...appProps, browserLanguage}
}

export default CustomApp
