import {createMuiTheme} from '@material-ui/core/styles'
import {theme as defaultTheme} from 'theme'

export const drawerWidth = 223

export const layoutTheme = createMuiTheme({
    overrides: {
        MuiDrawer: {
            root: {
                width: drawerWidth,
            },
            paper: {
                width: drawerWidth,
                color: '#FFF',
                background: '#024935',
            },
        },
        MuiListItem: {
            root: {
                paddingLeft: '12px !important',
                borderLeft: '2px solid transparent',
                '&$selected': {
                    color: '#FFF',
                    backgroundColor: '#FF7F0B',
                    '&:hover': {
                        backgroundColor: '#FF7F0B',
                    },
                },
            },
            button: {
                paddingLeft: defaultTheme.spacing(4),
                paddingRight: defaultTheme.spacing(4),
                '&:hover': {
                    backgroundColor: '#FF7F0B',
                },
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 0,
                marginRight: defaultTheme.spacing(1),
                color: 'inherit',
            },
        },
    },
}, defaultTheme)
