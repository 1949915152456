import {Box, Drawer, Hidden, IconButton, SwipeableDrawer, ThemeProvider, Toolbar, useMediaQuery} from '@material-ui/core'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {ChevronLeft, ChevronRight, Home as HomeIcon, Menu as MenuIcon, Person as PersonIcon} from '@material-ui/icons'
import {NotificationBell} from 'components/notifications/notification-bell'
import {useUser} from 'contexts/user'
import {useRouter} from 'next/router'
import React, {FC, useReducer} from 'react'

import {AppMenu} from './app-menu'
import {drawerWidth, layoutTheme} from './theme'

type ReducerState = {
    mobileOpen: boolean
    desktopOpen: boolean
    hoverEnabled: boolean
}

type ReducerActions =
    | {type: 'open-mobile'}
    | {type: 'close-mobile'}
    | {type: 'toggle-mobile'}
    | {type: 'hover-open'}
    | {type: 'hover-close'}
    | {type: 'close-desktop'}
    | {type: 'desktop-toggle'}

function reducer(state: ReducerState, action: ReducerActions): ReducerState {
    switch (action.type) {
        case 'open-mobile':
            return {...state, mobileOpen: true}
        case 'close-mobile':
            return {...state, mobileOpen: false}
        case 'toggle-mobile':
            return {...state, mobileOpen: !state.mobileOpen}
        case 'hover-open':
            if (!state.hoverEnabled) return state
            return {...state, desktopOpen: true}
        case 'hover-close':
            if (!state.hoverEnabled) return state
            return {...state, desktopOpen: false}
        case 'close-desktop':
            return {...state, desktopOpen: false}
        case 'desktop-toggle':
            return {
                ...state,
                desktopOpen: !state.desktopOpen,
                hoverEnabled: !!state.desktopOpen,
            }
        default:
            return state
    }
}

export const Layout: FC = ({children}) => {
    const router = useRouter()
    const print = useMediaQuery('print') || Boolean(router.query.print)
    const [state, dispatch] = useReducer(reducer, {
        mobileOpen: false,
        desktopOpen: true,
        hoverEnabled: false,
    })
    const classes = useStyles(state)
    const {user} = useUser()

    const inHome = router.pathname === '/home' || router.pathname === '/'

    if (print) return <>{children}</>

    return (
        <div className={(user && !inHome) ? classes.root : undefined}>
            {user && (
                <ThemeProvider theme={layoutTheme}>
                    <Hidden mdUp={true}>
                        <SwipeableDrawer
                            keepMounted={true}
                            open={state.mobileOpen}
                            onOpen={() => dispatch({type: 'open-mobile'})}
                            onClose={() => dispatch({type: 'close-mobile'})}>
                            <AppMenu
                                onClose={() => {
                                    dispatch({type: 'close-mobile'})
                                }}
                                drawerExpanded={state.mobileOpen}
                            />
                        </SwipeableDrawer>
                    </Hidden>
                    <Hidden smDown={true}>
                        {!inHome && (
                            <Drawer
                                open={true}
                                variant='permanent'
                                onMouseOver={() => dispatch({type: 'hover-open'})}
                                onMouseLeave={() => dispatch({type: 'hover-close'})}
                                className={`${classes.drawer} ${state.desktopOpen && classes.drawerOpen || ''} ${!state.desktopOpen && classes.drawerClose || ''}`}
                                classes={{
                                    paper: `${classes.hiddenScrollBar} ${state.desktopOpen && classes.drawerOpen || ''} ${!state.desktopOpen && classes.drawerClose || ''}`,
                                }}>
                                <Box
                                    onMouseOver={e => e.stopPropagation()}
                                    mx={-1}
                                    pl={4}
                                    display='flex'
                                    justifyContent={state.desktopOpen && 'space-between' || 'center'}
                                    alignItems='center'>
                                    {state.desktopOpen && user.displayName}
                                    <IconButton
                                        color='inherit'
                                        onClick={() => dispatch({type: 'desktop-toggle'})}>
                                        {!state.desktopOpen ? <ChevronRight /> : <ChevronLeft />}
                                    </IconButton>
                                </Box>
                                <AppMenu drawerExpanded={state.desktopOpen} />
                            </Drawer>
                        )}
                    </Hidden>
                </ThemeProvider>
            )
            }
            <main className={user && classes.main}>
                {user && !inHome && (
                    <Hidden mdUp={true}>
                        <Toolbar className={classes.toolbar}>
                            <IconButton
                                color='primary'
                                onClick={() => dispatch({type: 'toggle-mobile'})}>
                                <MenuIcon htmlColor='white' />
                            </IconButton>
                            <div>
                                <NotificationBell color='white' />
                                <IconButton
                                    color='primary'
                                    onClick={() => router.push('/home')}>
                                    <HomeIcon htmlColor='white' />
                                </IconButton>
                                <IconButton
                                    color='primary'
                                    onClick={() => router.push('/users/profile')}>
                                    <PersonIcon htmlColor='white' />
                                </IconButton>
                            </div>
                        </Toolbar>
                    </Hidden>
                )}
                {children}
            </main>
        </div>
    )
}

const useStyles = makeStyles<Theme, ReducerState>(theme => createStyles({
    root: {
        '@media screen': {
            [theme.breakpoints.up('md')]: {
                marginLeft: (state: ReducerState) => state.desktopOpen && !state.hoverEnabled
                    ? drawerWidth
                    : theme.spacing(7) + 1,
            },
            transition: theme.transitions.create('marginLeft', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
    },
    main: {
        '@media screen': {
            [theme.breakpoints.up('md')]: {
                flexGrow: 1,
            },
        },
    },
    toolbar: {
        justifyContent: 'space-between',
        backgroundColor: theme.palette.primary.dark,
    },

    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        overflowX: 'visible',
    },
    hiddenScrollBar: {
        '-ms-overflow-style': 'none', /* IE and Edge */
        'scrollbar-width': 'none', /* Firefox */
        '&::-webkit-scrollbar': {
            display: 'none', /* Hide scrollbar for Chrome, Safari and Opera */
        },
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
    },
}))
