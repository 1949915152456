import {ApolloClient, InMemoryCache} from '@apollo/client'
import {createUploadLink} from 'apollo-upload-client'
import {ENVIROMENT} from 'settings'

const isBrow = typeof window !== 'undefined'
const tenant = isBrow && ENVIROMENT === 'development' && new URLSearchParams(window.location.search).get('tenant')

export const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: createUploadLink({
        uri: `/graphql/${tenant && `?tenant=${tenant}` || ''}`,
        credentials: 'include',
    }),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-and-network',
        },
    },
})
